// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  height: 100%;
}

.MuiOutlinedInput-root {
  height: 100% !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.MuiAutocomplete-input {
  font-size: 14px !important;
  color: #495365 !important;
}
.MuiAutocomplete-input::placeholder {
  font-size: 14px !important;
  color: #495365 !important;
  opacity: 1 !important;
}

.MuiAutocomplete-input::-webkit-input-placeholder {
  font-size: 14px !important;
  color: #495365 !important;
  opacity: 1 !important;
}

.MuiAutocomplete-popper {
  margin: 10px 0 !important;
}

.MuiAutocomplete-paper {
  font-size: 14px !important;
  color: #495365 !important;
}

.MuiAutocomplete-endAdornment {
  right: 20px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/AutoComplete/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,0BAA0B;EAC1B,yBAAyB;AAC3B;AACA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".css-wb57ya-MuiFormControl-root-MuiTextField-root {\n  height: 100%;\n}\n\n.MuiOutlinedInput-root {\n  height: 100% !important;\n}\n\n.css-1d3z3hw-MuiOutlinedInput-notchedOutline {\n  border: none !important;\n}\n.MuiAutocomplete-input {\n  font-size: 14px !important;\n  color: #495365 !important;\n}\n.MuiAutocomplete-input::placeholder {\n  font-size: 14px !important;\n  color: #495365 !important;\n  opacity: 1 !important;\n}\n\n.MuiAutocomplete-input::-webkit-input-placeholder {\n  font-size: 14px !important;\n  color: #495365 !important;\n  opacity: 1 !important;\n}\n\n.MuiAutocomplete-popper {\n  margin: 10px 0 !important;\n}\n\n.MuiAutocomplete-paper {\n  font-size: 14px !important;\n  color: #495365 !important;\n}\n\n.MuiAutocomplete-endAdornment {\n  right: 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
