import React, { useEffect, useState, useRef } from "react";

import FiltersIcon from "../Assets/ic_equalizer.svg";
import sojaIcon from "../Assets/ic_soja.svg";

import { useSelector, useDispatch } from "react-redux";
import useNearScreen from "../hooks/useNearScreen";

import "./Home.css";
import { ProductItem } from "../components/ProductItem/ProductItem";
import RightSidebar from "../components/RightSidebar/RightSidebar";
import { producersAction } from "../actions/producersAction";
import { axiosCircuitExporter } from "../config/axios";
import SearchList from "../components/SearchList/SearchList";
import AutoComplete from "../components/AutoComplete/AutoComplete";
import CustomInput from "../components/CustomInput/CustomInput";
import { PAGING_PRODUCERS, RESET_PRODUCERS } from "../types";
import CampaingSelect from "../components/CampaingSelect/CampaingSelect";

const Home = () => {
  const externalRef = useRef();
  const childRef = useRef();
  const inputRef = useRef();
  const timer = useRef();
  const dispatch = useDispatch();

  const [sidebarTo, setsidebarTo] = useState("");
  const [paramsToFilter, setparamsToFilter] = useState({});
  const [searchQuery, setsearchQuery] = useState("");
  const [inputIsFocus, setInputIsFocus] = useState(false);

  const [provincesList, setprovincesList] = useState([]);
  const [departmentList, setdepartmentList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [selectedDepartments, setselectedDepartments] = useState([]);
  const [farmToSearch, setfarmToSearch] = useState("");
  const [campaignsList, setCampaignsList] = useState([]);
  const [campaignSelected, setCampaignSelected] = useState("");

  const isDepartamentsDisabled = Boolean(!selectedProvince);

  const userActual = useSelector((state) => {
    return state.token;
  });


  const { token, user } = userActual;
  useEffect(() => {
    async function getCampaigns() {
      try {
        const response = await axiosCircuitExporter.get(
          `/v2/data-bi/campaigns/${user.identifier}`
        );
        const { status, data } = response;
        if (status === 200 && data?.length) {
          setCampaignsList(data);
          setCampaignSelected(data[0]);
          setparamsToFilter((prevValues) => ({
            ...prevValues,
            licenseCampaign: data[0],
          }));
          dispatch(producersAction(user, { licenseCampaign: data[0] }));
        }
      } catch (error) {
        console.log(error);
      }
    }
    getCampaigns();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [paramsToFilter]);

  const totes = useSelector((state) => {
    return state.totes.totes;
  });

  const producers = useSelector((state) => {
    return state.producers.producers;
  });

  useEffect(() => {
    async function getProvinces() {
      try {
        const options = {
          method: "GET",
          params: {
            license_campaign: campaignSelected,
          },
        };
        const response = await axiosCircuitExporter(
          `/v2/data-bi/provinces/${user.identifier}`,
          options
        );
        const { status, data } = response;
        if (status === 200) {
          setprovincesList([...data]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (campaignSelected) {
      if (selectedProvince) {
        setselectedProvince(null);
        setselectedDepartments([]);
      }
      getProvinces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignSelected, user.identifier]);

  async function getDepartmentByProvince(province) {
    const options = {
      method: "GET",
      params: {
        province,
        license_campaign: campaignSelected,
      },
    };
    const response = await axiosCircuitExporter(
      `/v2/data-bi/departments/${user.identifier}`,
      options
    );

    const { status, data } = response;
    if (status === 200) {
      setdepartmentList([...data]);
    }
  }

  const handleShowSidebar = (to) => {
    setsidebarTo(to);
    let body = document.querySelector("body");

    if (body.hasAttribute("style")) {
      body.removeAttribute("style");
    } else {
      body.style.paddingRight = "17px";
    }
    body.classList.toggle("prevent_scrolling");
    let element = document.getElementById("sidebar_overlay");
    element.classList.toggle("active");
    let sidebar = document.getElementById("sidebar_content");
    sidebar.classList.toggle("active");
  };

  const handleSubmit = ({ toFilter = null, toSearch = "", toClear }) => {
    let toSearchCleaned = "";
    let toFilters = false;
    if (toSearch.length > 0) {
      const cuitsCleaned = toSearch.replace(/\s/g, ",");

      const cuitsCleaned2 = cuitsCleaned.replace(/;/g, ",");

      const cuitsCleaned3 = cuitsCleaned2.replace(/,+/g, ",");

      toSearchCleaned = cuitsCleaned3.replace(/^,|,$/g, "");
    }

    dispatch(pagingProducers({ page: 1, next: null }));

    dispatch(resetProducers());

    if (toClear === "resetFilters") {
      setparamsToFilter((prevValues) => ({
        ...prevValues,
        search: toSearchCleaned,
        filters: null,
      }));

      dispatch(
        producersAction(user, {
          ...paramsToFilter,
          search: toSearchCleaned,
          filters: null,
        })
      );
      return;
    }
    if (toClear === "resetSearch") {
      setparamsToFilter((prevValues) => ({
        ...prevValues,
        search: "",
        filters: toFilters ? toFilter : null,
      }));

      dispatch(
        producersAction(user, {
          ...paramsToFilter,
          search: "",
          filters: toFilter.noFilters === false ? toFilter : null,
        })
      );
      return;
    }
    if (toSearchCleaned.length) {
      setsearchQuery("");
      setparamsToFilter((prevValues) => ({
        ...prevValues,
        search: toSearchCleaned,
        filters: toFilter.noFilters === false ? toFilter : null,
        querySearch: "",
      }));
    } else {
      setparamsToFilter((prevValues) => ({
        ...prevValues,
        search: toSearchCleaned,
        filters: toFilter.noFilters === false ? toFilter : null,
      }));
    }

    dispatch(
      producersAction(user, {
        ...paramsToFilter,
        querySearch: toSearchCleaned.length ? "" : searchQuery,
        search: toSearchCleaned,
        filters: toFilter.noFilters === false ? toFilter : null,
      })
    );
  };

  const onChangeSearchQuery = (e) => {
    const search = e.target.value.replace(/^\s+/g, "");
    setsearchQuery(search);
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setparamsToFilter((prevValues) => ({
        ...prevValues,
        querySearch: search,
        search: "",
      }));
      dispatch(pagingProducers({ page: 1, next: null }));
      dispatch(resetProducers());
      dispatch(
        producersAction(user, {
          ...paramsToFilter,
          querySearch: search,
          search: "",
        })
      );
      if (paramsToFilter?.search?.length > 0) childRef.current.clearCuits();
    }, 1000);
  };

  const onClearSearchQuery = () => {
    setsearchQuery("");
    setparamsToFilter((prevValues) => ({ ...prevValues, querySearch: "" }));
    inputRef?.current?.blur();
    dispatch(pagingProducers({ page: 1, next: null }));
    dispatch(resetProducers());
    dispatch(producersAction(user, { ...paramsToFilter, querySearch: "" }));
  };

  const onChangeFarmToSearch = (e) => {
    const farm = e.target.value;
    setfarmToSearch(farm);

    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setparamsToFilter((prevValues) => ({
        ...prevValues,
        farm,
      }));
      dispatch(pagingProducers({ page: 1, next: null }));
      dispatch(resetProducers());
      dispatch(
        producersAction(user, {
          ...paramsToFilter,
          farm,
        })
      );
      if (paramsToFilter?.search?.length > 0) childRef.current.clearCuits();
    }, 1000);
  };

  const onClearFarmToSearch = () => {
    setfarmToSearch("");
    setparamsToFilter((prevValues) => ({ ...prevValues, farm: "" }));
    inputRef?.current?.blur();
    dispatch(pagingProducers({ page: 1, next: null }));
    dispatch(resetProducers());
    dispatch(producersAction(user, { ...paramsToFilter, farm: "" }));
  };

  const onChangeProvince = (province) => {
    dispatch(pagingProducers({ page: 1, next: null }));
    dispatch(resetProducers());
    if (province !== null) {
      setparamsToFilter((prevValues) => ({ ...prevValues, province }));
      dispatch(producersAction(user, { ...paramsToFilter, province }));
    } else {
      setparamsToFilter((prevValues) => ({
        ...prevValues,
        province,
        departments: [],
      }));
      dispatch(
        producersAction(user, { ...paramsToFilter, province, departments: [] })
      );
    }
  };

  const onChangeDepartment = (departments) => {
    setparamsToFilter((prevValues) => ({ ...prevValues, departments }));
    dispatch(pagingProducers({ page: 1, next: null }));
    dispatch(resetProducers());
    dispatch(producersAction(user, { ...paramsToFilter, departments }));
  };

  const onChangeCampaign = (campaign) => {
    setparamsToFilter((prevValues) => ({
      ...prevValues,
      licenseCampaign: campaign,
      province: null,
      departments: [],
    }));
    setCampaignSelected(campaign);
    dispatch(pagingProducers({ page: 1, next: null }));
    dispatch(resetProducers());
    dispatch(
      producersAction(user, {
        ...paramsToFilter,
        licenseCampaign: campaign,
        province: null,
        departments: [],
      })
    );
  };

  const pagingProducers = (options) => ({
    type: PAGING_PRODUCERS,
    payload: options,
  });

  const resetProducers = () => ({
    type: RESET_PRODUCERS,
  });
  const [scrollTop, setScrollTop] = useState(0);
  const [prevPositionScroll, setprevPositionScroll] = useState(0);
  const titleRef = useRef(null);
  const filtersObserver = useRef(null);
  const inputContainerRef = useRef(null);

  const { isNearScreen: isVisible } = useNearScreen({
    distance: "1px",
    externalRef: filtersObserver,
    once: false,
  });

  useEffect(() => {
    const getPosition = () => {
      if (titleRef && inputContainerRef) {
        const { width } = inputContainerRef.current.getBoundingClientRect();
        let root = document.documentElement;
        root.style.setProperty("--ew", width + "px");
        setScrollTop(window.scrollY);
        if (prevPositionScroll > scrollTop && !isVisible) {
          titleRef.current.classList.add("subtitleFixed");
          titleRef.current.classList.remove("subtitleFixedHide");
          inputContainerRef.current.classList.add("inputsContainerFixed");
          inputContainerRef.current.classList.remove(
            "inputsContainerFixedHide"
          );
        } else {
          titleRef.current.classList.add("subtitleFixedHide");
          inputContainerRef.current.classList.add("inputsContainerFixedHide");
        }
        setprevPositionScroll(scrollTop);
      }
    };

    if (isVisible) {
      titleRef.current.classList.remove("subtitleFixed");
      titleRef.current.classList.remove("subtitleFixedHide");
      inputContainerRef.current.classList.remove("inputsContainerFixed");
      inputContainerRef.current.classList.remove("inputsContainerFixedHide");
    }

    window.addEventListener("scroll", getPosition);
    return () => {
      window.removeEventListener("scroll", getPosition);
    };
  }, [isVisible, prevPositionScroll, scrollTop]);

  return (
    <div className="container d-flex flex-column">
      <div className="titleSection">
        <img alt="soja-icon" src={sojaIcon} />
        <h1 className="title mb-0">Soja</h1>
        <CampaingSelect
          campaignsList={campaignsList}
          campaignSelected={campaignSelected}
          onChangeCampaign={onChangeCampaign}
        />
      </div>
      <div className="countProducers">
        {/*  <div ref={titleFakeRef}></div> */}
        <h3 className="subtitle subtitleStatic" ref={titleRef}>
          {totes?.totalFarms} {totes?.totalFarms === 1 ? "Campo" : "Campos"}
        </h3>
      </div>
      <div className="producersContainer row justify-content-between">
        <div className="col-8">
          <div className="min-h-162" ref={filtersObserver}>
            <div
              className="inputsContainer justify-content-between"
              ref={inputContainerRef}
            >
              <div className="d-flex flex-column first position-relative">
                <span className="inputLabel">Razon Social / CUIT</span>
                <div className="">
                  {inputIsFocus && (
                    <div
                      className="searchListOverlay"
                      onClick={() => setInputIsFocus(false)}
                    ></div>
                  )}
                  <CustomInput
                    placeholder="Ver todo"
                    value={searchQuery}
                    onChange={onChangeSearchQuery}
                    onClickClear={onClearSearchQuery}
                    inputRef={inputRef}
                    onFocus={() => setInputIsFocus(true)}
                  />
                </div>
                <SearchList
                  searchQuery={searchQuery}
                  setsearchQuery={setsearchQuery}
                  inputIsFocus={inputIsFocus}
                  setInputIsFocus={setInputIsFocus}
                  paramsToFilter={paramsToFilter}
                  setparamsToFilter={setparamsToFilter}
                />
              </div>
              <div className="d-flex flex-column second">
                <div className="d-flex justify-content-between align-items-center avancedSearchContainer">
                  <span className="inputLabel">Campo</span>
                  <button
                    className=""
                    onClick={() => handleShowSidebar("toSearch")}
                  >
                    Búsqueda avanzada
                  </button>
                </div>
                <CustomInput
                  placeholder="Ver todo"
                  value={farmToSearch}
                  onChange={onChangeFarmToSearch}
                  onClickClear={onClearFarmToSearch}
                  inputRef={inputRef}
                />
              </div>
              <div className="d-flex align-items-end third">
                <div className="listIcons">
                  <button className="iconItem">
                    <img
                      alt="filtersIcon"
                      src={FiltersIcon}
                      onClick={() => handleShowSidebar("toFilter")}
                    />
                  </button>
                </div>
              </div>
              <div className="d-flex align-items-start fourth">
                <AutoComplete
                  value={selectedProvince}
                  options={provincesList}
                  placeholder="Provincia"
                  onChange={(event, value) => {
                    setselectedProvince(value);
                    setselectedDepartments([]);
                    onChangeProvince(value);
                    if (value !== null) {
                      getDepartmentByProvince(value);
                    }
                  }}
                  noOptionsText="Provincia no encontrada"
                />
              </div>
              <div className="d-flex align-items-end fifth">
                <AutoComplete
                  value={selectedDepartments}
                  options={departmentList}
                  placeholder="Departamentos"
                  multiple
                  limitTags={3}
                  onChange={(event, value) => {
                    setselectedDepartments(value);
                    onChangeDepartment(value);
                  }}
                  isDisabled={isDepartamentsDisabled}
                  noOptionsText="Departamento no encontrado"
                />
              </div>
            </div>
          </div>
          <div className="listProducers">
            {producers?.length > 0 &&
              producers?.map((producer, index) => {
                return (
                  <ProductItem
                    index={index}
                    paramsToFilter={paramsToFilter}
                    key={producer.id_table}
                    producer={producer}
                    tonsStatus={producer?.tonsStatus}
                    ton={producer?.tonsRequested}
                    idHash={producer.id_hash}
                    department={producer.departamento}
                    province={producer.provincia}
                  />
                );
              })}
          </div>
          <div ref={externalRef}></div>
        </div>
        <div className="col-3">
          <div className="statisticsContainer">
            <div className="statistics">
              <div className="d-flex">
                <h3>{totes?.sumTonsRequested.toFixed(2)}</h3>&nbsp;
                <span>tn</span>
              </div>
              <p>Reclamadas completadas</p>
            </div>
            <div className="statistics">
              <div className="d-flex">
                <h3>{totes?.sumQuantityUnFinished.toFixed(2)}</h3>&nbsp;
                <span>tn</span>
              </div>
              <p>Reclamadas sin terminar</p>
            </div>
            <div className="statistics">
              <div className="d-flex">
                <h3>{totes?.sumtonsAssigned.toFixed(2)}</h3>&nbsp;
                <span>tn</span>
              </div>
              <p>
                Verificadas por <strong>Surveyor</strong>
              </p>
            </div>
            <div className="statistics">
              <div className="d-flex">
                <h3>
                  {totes?.sumQuantityAvailable &&
                    totes?.sumQuantityAvailable
                      ?.toFixed(2)
                      .toLocaleString("es-ES")}
                </h3>
                &nbsp;<span>tn</span>
              </div>
              <p>Total producidas</p>
            </div>
          </div>
        </div>
      </div>
      <RightSidebar
        ref={childRef}
        sidebarTo={sidebarTo}
        handleShowSidebar={handleShowSidebar}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default Home;
