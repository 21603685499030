// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateEvidence_custom-file-button__uoBsz input[type=file] {
  margin-left: -2px !important;
}
.CreateEvidence_custom-file-button__uoBsz input[type=file]::file-selector-button {
  display: none;
}
.CreateEvidence_custom-file-button__uoBsz:hover label {
  background-color: #dde0e3;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/QR/Trace/CreateEvidence.module.scss"],"names":[],"mappings":"AACE;EACE,4BAAA;AAAJ;AAKI;EACE,aAAA;AAAN;AAKI;EACE,yBAAA;EACA,eAAA;AAHN","sourcesContent":[".custom-file-button {\n  input[type=\"file\"] {\n    margin-left: -2px !important;\n\n    &::-webkit-file-upload-button {\n      display: none;\n    }\n    &::file-selector-button {\n      display: none;\n    }\n  }\n\n  &:hover {\n    label {\n      background-color: #dde0e3;\n      cursor: pointer;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-file-button": `CreateEvidence_custom-file-button__uoBsz`
};
export default ___CSS_LOADER_EXPORT___;
