// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request-sent {
    background: #FFFFFF;
    width: 180px;
    height: 68px;
    color: #4CAF50;
    border: 2px solid #4CAF50;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}
.request-sent .request-sent-text {
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    width: 45%;
}
`, "",{"version":3,"sources":["webpack://./src/components/buttons-request-ton/request-sent/request-sent.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,UAAU;AACd","sourcesContent":[".request-sent {\n    background: #FFFFFF;\n    width: 180px;\n    height: 68px;\n    color: #4CAF50;\n    border: 2px solid #4CAF50;\n    border-radius: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 1rem;\n}\n.request-sent .request-sent-text {\n    text-align: left;\n    font-size: 14px;\n    font-weight: 700;\n    width: 45%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
