import {useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { EDIT_BATCH, CREATE_BATCH, DELETE_BATCH_EVIDENCE, DELETE_BATCH_POINT } from "../../../graphql/trace/getQrBatches";
import CreateEvidence from "./CreateEvidence";
import BatchEvidence from "./BatchEvidence";
import { Grid, Box, Button, Typography, Snackbar, Alert } from "@mui/material";
import CreateMapPoint from "./CreateMapPoint";
import BatchPoint from "./BatchPoint";
import { CloudUpload, SaveAlt, Delete as DeleteIcon } from '@mui/icons-material'; // Import delete icon
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

function EditFormItem(props) {
  const {
    id,
    title, // Header title
    batch,
    batchEvidences,
    refetch,
    batchPoints,
    mapId,
    productId,
    qrId
  } = props;

  const { points } = batch;

  const [batchNumber, setBatchNumber] = useState(batch?.batch_number);
  const [description, setDescription] = useState(batch?.description || "");
  const [showDescription, setShowDescription] = useState(!!description); // Show only if there is a description
  const toggleDescriptionField = () => {
    setShowDescription(!showDescription);
  };
  const descriptionString = `${description}`;

  const [volume, setVolume] = useState(batch?.quantity);
  const [unit, setUnit] = useState(batch?.unit);
  const [locationBatch, setLocationBatch] = useState({
    lat: null,
    lng: null,
  });

  const [editBatch] = useMutation(EDIT_BATCH);
  const [createBatch] = useMutation(CREATE_BATCH);
  const [deleteEvidence] = useMutation(DELETE_BATCH_EVIDENCE); // Mutation for deleting evidence
  const [deletePoint] = useMutation(DELETE_BATCH_POINT); // Mutation for deleting a point

  const [show, setShow] = useState(false);
  const [showModalMapPoint, setShowModalMapPoint] = useState(false);

  const [edit, setEdit] = useState(false);
  const [evidenceToEdit, setEvidenceToEdit] = useState({});

  // Estado para refrescar el componente web
  const [refreshKey, setRefreshKey] = useState(0);

  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const handleClose = () => {
    setEdit(false);
    setEvidenceToEdit({});
    setShow(false);
    setShowModalMapPoint(false);
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const handleShow = () => setShow(true);

  //const { qrId } = useParams();

  const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

  const handleSubmitBatch = async () => {
    try {
      // Validación de campos: "Cantidad" y "Unidad" deben completarse juntos o dejarse vacíos
      const isVolumeEmpty = volume === null || volume === undefined || volume === "";
      const isUnitEmpty = !unit || unit.trim() === "";
      if ((isVolumeEmpty && !isUnitEmpty) || (!isVolumeEmpty && isUnitEmpty)) {
        setSnackbar({
          open: true,
          message: "Por favor, completa ambos campos: Cantidad y Unidad, o déjalos vacíos.",
          severity: "error",
        });
        return; // Detener el envío si la validación falla
      }
  
      if (!!batch) {
        const data = {
          batch_number: batchNumber,
          quantity: isVolumeEmpty ? null : +volume, // Si ambos están vacíos, enviamos null
          unit: isUnitEmpty ? null : unit,
          description: description,
        };
        await editBatch({ variables: { id: batch?.id, data: data } });
        setSnackbar({ open: true, message: "Batch editado con éxito", severity: "success" });
        refetch();
        setRefreshKey((prevKey) => prevKey + 1);
      } else {
        const data = {
          batch_number: batchNumber,
          quantity: isVolumeEmpty ? null : +volume,
          unit: isUnitEmpty ? null : unit,
          description: description,
          qr_id: { id: qrId },
          stage_id: { id: id },
        };
        await createBatch({ variables: { data: data } });
        setSnackbar({ open: true, message: "Batch creado con éxito", severity: "success" });
        refetch();
        setRefreshKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      console.error("Hubo un problema:", error);
      setSnackbar({ open: true, message: "Ocurrió un error al guardar", severity: "error" });
    }
  };
  
  
  // New delete evidence handler
  const handleDeleteEvidence = async (evidenceId) => {
    try {
      // Confirm before deleting
      const confirmDelete = window.confirm(
        "¿Estás seguro de que deseas eliminar esta evidencia?"
      );
      if (!confirmDelete) return;

      // Execute the deletion mutation
      await deleteEvidence({
        variables: {
          id: evidenceId, // ID of the evidence to delete
        },
      });

      // Refetch or update the list of evidences after deletion
      refetch();
      setSnackbar({ open: true, message: "Evidence deleted successfully", severity: "success" });
      setRefreshKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("Error al eliminar la evidencia:", error);
      setSnackbar({ open: true, message: "Error deleting evidence", severity: "error" });
    }
  };

 // Delete point handler
 const handleDeletePoint = async (pointId) => {
  try {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar esta ubicación?"
    );
    if (!confirmDelete) return;

    await deletePoint({
      variables: { id: pointId }, // Assuming `pointId` is the identifier for the point to be deleted
    });
    refetch();
    alert("Ubicación eliminada correctamente.");
  } catch (error) {
    console.error("Error al eliminar la ubicación:", error);
  }
};


  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // Dynamically load the web component script
  useEffect(() => {
    if (!customElements.get("ucropit-trace-web")) {
      console.log("Web component already registered.");
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-7">
          <div className="card my-3">
            <div className="card-body">
              <div className="">
                <div className="flex-fill">
                  <p>Definir Lote: {title}</p>
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-default"
                    >
                      #
                    </span>
                    <input
                      value={batchNumber || ""}
                      className="form-control"
                      name="batchNumber"
                      onChange={(e) => setBatchNumber(e.target.value)}
                    />
                  </div>

                  <div className="d-flex mb-3">
                    <div className="input-group me-3">
                      <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                      >
                        Cantidad
                      </span>
                      <input
                        value={volume ?? ""}
                        type="number"
                        className="form-control"
                        name="volume"
                        onChange={(e) => setVolume(e.target.value)}
                      />
                    </div>
                    <div className="input-group">
                      <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                      >
                        Unidad
                      </span>
                      <input
                        value={unit || ""}
                        type="text"
                        className="form-control"
                        name="unit"
                        onChange={(e) => setUnit(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Toggle Button for Description */}
                  <Button
                    variant="text"
                    onClick={toggleDescriptionField}
                  >
                    {showDescription ? "Ocultar Descripción" : "Agregar una Descripción"}
                  </Button>
                  
                  {/* Conditionally Render Description Input */}
                  {showDescription && (
                    <ReactQuill
                      theme="snow"
                      value={description}
                      onChange={setDescription}
                      placeholder="Add a description..."
                    />
                  )}

                  {!!batch ? (
                    <>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} pt={2}>
                        {batchEvidences?.length ? (
                          <Typography variant="h6">Evidencias:</Typography>
                        ) : (
                          <Typography variant="h6">Sin evidencias</Typography>
                        )}
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={handleShow}
                          startIcon={<CloudUpload />}
                        >
                          Nueva Evidencia
                        </Button>
                      </Box>

                      {/* Map through evidences and include Delete button */}
                      {batchEvidences?.map(({ evidence_id }, index) => (
                        <div key={index}>
                          <BatchEvidence
                            title={evidence_id.title}
                            subtitle={evidence_id.subtitle}
                            icon={evidence_id.icon}
                            handleDeleteEvidence={() => handleDeleteEvidence(evidence_id.id)} // Ensure delete handler is passed
                          />
                        </div>
                      ))}

                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} pt={2}>
                        {batchPoints?.length ? (
                          <Typography variant="h6">Ubicaciones:</Typography> 
                        ) : (
                          <Typography variant="h6">Sin ubicaciones</Typography>
                        )}
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={() => setShowModalMapPoint(true)}
                          startIcon={<CloudUpload />}
                        >
                          Nueva Ubicación
                        </Button>
                      </Box>

                      <Grid container spacing={1}>
                        {batchPoints?.map(({ evidence_id }, index) => (
                          <Grid item xs={12} sm={6} key={index}>
                            <BatchPoint
                              title={evidence_id.nombre}
                              subtitle={evidence_id.descripcion}
                              image={evidence_id.image}
                              lat={evidence_id.location[0].lat}
                              lng={evidence_id.location[0].lng}
                              handleDeletePoint={() => handleDeletePoint(evidence_id.id)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  ) : null}

                  <Box display="flex" justifyContent="flex-end" mt={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmitBatch}
                      startIcon={<SaveAlt />}
                    >
                      Guardar Cambios
                    </Button>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md">
          <Box mt={4}>
            <ucropit-trace-web
            key={refreshKey}
            product-id={productId}
            qr-id={qrId}
            stage={id}
          ></ucropit-trace-web>
          </Box>
        </div>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          TransitionComponent={undefined}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>

      {/* Modal for creating/editing evidence */}
      {show && (
        <CreateEvidence
          show={show}
          handleClose={handleClose}
          edit={edit}
          evidenceToEdit={evidenceToEdit}
          batchId={batch?.id}
          refetch={refetch}
        />
      )}

      {/* Modal for creating map points */}
      {showModalMapPoint && (
        <CreateMapPoint
          show={showModalMapPoint}
          handleClose={handleClose}
          batchId={batch?.id}
          mapId={mapId}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default EditFormItem;