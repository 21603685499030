// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.custom-tooltip {
    background-color: #FFFFFF !important;
    color: #858585 !important;
    font-size: 12px;
    font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/chips/chipsDDSS/style.css"],"names":[],"mappings":";AACA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["\n.custom-tooltip {\n    background-color: #FFFFFF !important;\n    color: #858585 !important;\n    font-size: 12px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
