import { ApolloClient, InMemoryCache } from "@apollo/client";

const createApolloClient = () => {
  return new ApolloClient({
    uri: `${process.env.REACT_APP_HOST_SERVER_API}/graphql`,
    cache: new InMemoryCache(),
  });
};

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_HOST_SERVER_API}/graphql`,
  cache: new InMemoryCache(),
});

export default createApolloClient;


