// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiSelect-icon {
  height: 24px !important;
  width: 24px !important;
  color: #495365 !important;
  background-color: #fffbfe !important;
  border: 1px solid #f4eff4;
  border-radius: 8px;
  right: 0 !important;
}

.MuiSelect-select {
  opacity: 0;
  min-width: 188px !important;
  max-width: 188px !important;
  padding: 0 !important;
}

.MuiOutlinedInput-notchedOutline {
  padding: 0 !important;
}

.MuiSelect-nativeInput {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

.MuiPaper-root {
  background: #ffffff !important;
  border: 1px solid #f4eff4 !important;

}

.MuiList-root {
  padding-top: 15px !important;
  padding-right: 1px !important;
  padding-left: 1px !important;
}

.MuiMenuItem-root {
  font-size: 14px !important;
  line-height: 20px !important;
  color: #939094 !important;
}

.Mui-selected {
  background: #f3f4f7 !important;
  color: #111827 !important;
  font-weight: 600 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/CampaingSelect/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,yBAAyB;EACzB,oCAAoC;EACpC,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,2BAA2B;EAC3B,2BAA2B;EAC3B,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;EACE,8BAA8B;EAC9B,oCAAoC;;AAEtC;;AAEA;EACE,4BAA4B;EAC5B,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;EACE,0BAA0B;EAC1B,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,2BAA2B;AAC7B","sourcesContent":[".MuiSelect-icon {\n  height: 24px !important;\n  width: 24px !important;\n  color: #495365 !important;\n  background-color: #fffbfe !important;\n  border: 1px solid #f4eff4;\n  border-radius: 8px;\n  right: 0 !important;\n}\n\n.MuiSelect-select {\n  opacity: 0;\n  min-width: 188px !important;\n  max-width: 188px !important;\n  padding: 0 !important;\n}\n\n.MuiOutlinedInput-notchedOutline {\n  padding: 0 !important;\n}\n\n.MuiSelect-nativeInput {\n  margin: 0 !important;\n  padding: 0 !important;\n  border: 0 !important;\n}\n\n.MuiPaper-root {\n  background: #ffffff !important;\n  border: 1px solid #f4eff4 !important;\n\n}\n\n.MuiList-root {\n  padding-top: 15px !important;\n  padding-right: 1px !important;\n  padding-left: 1px !important;\n}\n\n.MuiMenuItem-root {\n  font-size: 14px !important;\n  line-height: 20px !important;\n  color: #939094 !important;\n}\n\n.Mui-selected {\n  background: #f3f4f7 !important;\n  color: #111827 !important;\n  font-weight: 600 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
