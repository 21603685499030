// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  height: 56px;
  background: #FAFAFA;
  border-top: 1px solid #EBEBEB;
}

.footer div span {
  font-size: 12px;
  color: #858585;
}

.footer .container span:first-child {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".footer {\n  height: 56px;\n  background: #FAFAFA;\n  border-top: 1px solid #EBEBEB;\n}\n\n.footer div span {\n  font-size: 12px;\n  color: #858585;\n}\n\n.footer .container span:first-child {\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
