import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./CreateEvidence.module.scss";
import { CREATE_BATCH_EVIDENCE } from "../../../graphql/trace/getQrBatches";
import { DELETE_BATCH_EVIDENCE } from "../../../graphql/trace/getQrBatches";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import ProgressBar from "react-bootstrap/ProgressBar";
import { generateFileHash } from "../../../utils/file";
import axios from "axios";
import ReactQuill from 'react-quill-new';
import { Tabs, Tab } from "react-bootstrap";


// Componente CreateEvidence para crear o editar evidencias asociadas a un batch
const CreateEvidence = ({ show, handleClose, edit, evidenceToEdit, batchId, refetch }) => {
  // Mutación de GraphQL para crear evidencias
  const [createEvidence] = useMutation(CREATE_BATCH_EVIDENCE);
  const [deleteEvidence] = useMutation(DELETE_BATCH_EVIDENCE);

  // Estados locales
  const [loading, setLoading] = useState(false);  // Indica si la operación está en curso
  const [progress, setProgress] = useState(0);    // Progreso de la subida de archivos

  const [activeTab, setActiveTab] = useState("archivo"); // Track active tab

  // Valores iniciales para Formik
  const initialValues = {
    title:       evidenceToEdit?.title ?? "",
    subtitle:    evidenceToEdit?.subtitle ?? "",
    description: evidenceToEdit?.description ?? "",
    icon:        evidenceToEdit?.icon ?? "",
    logo:        evidenceToEdit?.logo ?? "",
    evidence:    evidenceToEdit?.evidence ?? "",
    link:        evidenceToEdit?.link ?? "",
    ots:         evidenceToEdit?.ots ?? "",
  };

  // Función para actualizar el progreso de la subida de archivos
  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    const percentage = Math.floor((loaded * 100) / total);
    setProgress(percentage);
  };

  // Función para manejar el envío del formulario
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      setProgress(0);
  
      const formData = new FormData(); // For file uploads
      let result = [];
      let res;
  
      // Upload files if present
      if (values.icon || values.logo || values.evidence) {
        if (values.icon) {
          formData.append("title", `${batchId}_icon`);
          formData.append("file", values.icon);
        }
  
        if (values.logo) {
          formData.append("title", `${batchId}_logo`);
          formData.append("file", values.logo);
        }
  
        if (values.evidence) {
          formData.append("title", `${batchId}_evidence`);
          formData.append("file", values.evidence);
  
          // Generate hash for the evidence file
          const hashString = await generateFileHash(values.evidence);
  
          // Send hash to external service for OTS
          const resp = await axios.post(
            `${process.env.REACT_APP_STAMP_MS_API}/api/stamp`,
            { hash: hashString }
          );
  
          // Create Blob for OTS file
          const otsBlob = new Blob([resp.data.fileOts], { type: "octet/stream" });
          if (otsBlob) {
            formData.append("title", `${batchId}_ots`);
            formData.append("file", otsBlob, values.evidence.name + ".ots");
          }
        }
  
        // Only upload files if there are any to upload
        res = await axios.post(
          `${process.env.REACT_APP_HOST_SERVER_API}/files`,
          formData,
          { onUploadProgress }
        );

        setProgress(95); // Update progress bar
      }
  
      // Asegurarse de que dataFiles sea siempre un arreglo
      const dataFiles = res?.data?.data || [];

      const evidencePdf = dataFiles.find((item) => item.title === `${batchId}_evidence`) || "";
      const evidenceOts = dataFiles.find((item) => item.type === "octet/stream");
      const icon = dataFiles.find((item) => item.title === `${batchId}_icon`);
      const logo = dataFiles.find((item) => item.title === `${batchId}_logo`);

      // Preparar los datos para GraphQL
      const data = {
        title: values.title,
        subtitle: values.subtitle,
        description: values.description,
        link: values.link,
        batch_id: { id: batchId },
        evidence: evidencePdf || null, // Usar null si no está presente
        ots: evidenceOts || null,
        icon: icon || null,
        logo: logo || null,
      };
  
      // Call mutation to create evidence
      await createEvidence({
        variables: {
          data: data,
        },
      });
  
      setProgress(100);
      setLoading(false);
  
      refetch(); // Refresh evidence list
      handleClose(); // Close modal
    } catch (error) {
      console.error("Error al crear la evidencia:", error);
      setLoading(false);
    }
  };

    // Función para eliminar evidencia
const handleDelete = async () => {
  if (evidenceToEdit?.id) {
    try {
      // Confirmar antes de eliminar (opcional)
      const confirmDelete = window.confirm(
        "¿Estás seguro de que deseas eliminar esta evidencia?"
      );
      if (!confirmDelete) return;

      // Ejecutar la mutación de eliminación
      await deleteEvidence({
        variables: {
          id: evidenceToEdit.id, // ID de la evidencia a eliminar
        },
      });

      // Refrescar la lista después de eliminar
      refetch();

      // Cerrar el modal
      handleClose();
    } catch (error) {
      console.error("Error al eliminar la evidencia:", error);
    }
  }
};

  return (
    <Modal show={show} onHide={handleClose}>
      {/* Usamos Formik para manejar el estado del formulario */}
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <h5 className="modal-title fontOne textDark w-100" id="staticBackdropLabel">
                {edit ? "Editar Evidencia" : "Alta de evidencia para batch"}
              </h5>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center justify-content-between p-1">
                <div className="flex-fill">
                  {/* Campo para el título */}
                  <div className="input-group mb-3">
                    <span className="input-group-text">Título</span>
                    <input
                      value={values.title}
                      placeholder="Ingrese un título"
                      className="form-control"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  {/* Campo para el subtítulo */}
                  <div className="input-group mb-3">
                    <span className="input-group-text">Subtítulo</span>
                    <input
                      value={values.subtitle}
                      placeholder="Ingrese un subtítulo"
                      className="form-control"
                      name="subtitle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  {/* Campo para el ícono */}
                  <div className={`${styles["custom-file-button"]} input-group mb-3`}>
                    <label className="input-group-text" htmlFor="icon">Icono</label>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      id="icon"
                      onChange={(e) => setFieldValue("icon", e.target.files[0])}
                    />
                  </div>

                  {/* Campo para el logo */}
                  <div className={`${styles["custom-file-button"]} input-group mb-3`}>
                    <label className="input-group-text" htmlFor="logo">Logo</label>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      id="logo"
                      onChange={(e) => setFieldValue("logo", e.target.files[0])}
                    />
                  </div>

                  {/* Campo para la descripción */}
                  <p className="mb-2">Descripción</p>
                  <div className="mb-3">
                    <ReactQuill
                      theme="snow"
                      name="description"
                      style={{ width: "100%", margin: "0, 0, 20px, 0" }}
                      value={values.description}
                      onChange={(content) => setFieldValue("description", content)}
                      placeholder="Add a description..."
                    />
                  </div>


                  {/* Tabs for Archivo/Link */}
                  <Tabs
                    id="evidence-tabs"
                    activeKey={activeTab}
                    onSelect={(k) => {
                      setActiveTab(k);
                      if (k === "archivo") {
                        setFieldValue("link", ""); // Clear link field when switching to Archivo
                      } else if (k === "link") {
                        setFieldValue("evidence", ""); // Clear evidence field when switching to Link
                      }
                    }}
                    className="mb-3"
                  >
                    {/* Archivo Tab */}
                    <Tab eventKey="archivo" title="Archivo">
                      <div className={`${styles["custom-file-button"]} input-group mb-3`}>
                        <label className="input-group-text" htmlFor="evidence">Archivo</label>
                        <input
                          type="file"
                          accept=".pdf"
                          className="form-control"
                          id="evidence"
                          onChange={(e) => setFieldValue("evidence", e.target.files[0])}
                          disabled={activeTab !== "archivo"} // Disable when Link tab is active
                        />
                        <small id="evidenceHelp" className="form-text text-muted">
                          Solo se aceptan archivos en formato PDF. El tamaño máximo es de 2MB.
                        </small>
                      </div>
                    </Tab>

                    {/* Link Tab */}
                    <Tab eventKey="link" title="Link">
                      <div className="input-group mb-3">
                        <span className="input-group-text">Link</span>
                        <input
                          type="url"
                          value={values.link}
                          placeholder="Link a la Evidencia"
                          className="form-control"
                          name="link"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={activeTab !== "link"} // Disable when Archivo tab is active
                        />
                      </div>
                    </Tab>
                  </Tabs>
                  

                  {/* Barra de progreso */}
                  {loading && <ProgressBar animated now={progress} />}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-around d-flex gap-2">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading} // Deshabilitar mientras se está cargando
              >
                {loading ? "Cargando..." : edit ? "Actualizar" : "Guardar"} {/* Texto dinámico */}
              </button>
              {edit && (
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={handleDelete} // Llamar a la función para eliminar
                  disabled={loading} // También deshabilitar durante la carga si es necesario
                >
                  Eliminar
                </button>
              )}
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateEvidence;