// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.requestProducer .requestText {
    width: 45%;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
}
.specificTons {
    width: 100%;
    padding: 10px 10px;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    background-color: #FFFFFF;
    border: 2px solid #4CAF50;
    border-radius: 10px;
    color: #4CAF50;
    height: 70px;
}
.input-tons-requested {
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
.button-create-edit{
    background-color: #0000;
    border: none;
    outline: none;
    color: #4CAF50;
    position: relative;
    left: 60%;
    top: -30%;
    margin-left: -70px;
    z-index: 10;
}
.requested {
    margin-left: 6px;
    opacity: 0.4;
}
.label-action {
    margin-top: -30px;
}
.input-suffix {
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: baseline;
}
.suffix{
    font-weight: 700;
    font-size: 20px;
    color: #000000;
}
`, "",{"version":3,"sources":["webpack://./src/components/request-producer/request-producer-button.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,qBAAqB;AACzB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".requestProducer .requestText {\n    width: 45%;\n    text-align: left;\n    font-size: 14px;\n    font-weight: 700;\n}\n.specificTons {\n    width: 100%;\n    padding: 10px 10px;\n    text-align: left;\n    font-size: 14px;\n    font-weight: 700;\n    background-color: #FFFFFF;\n    border: 2px solid #4CAF50;\n    border-radius: 10px;\n    color: #4CAF50;\n    height: 70px;\n}\n.input-tons-requested {\n    width: 100%;\n    border: none;\n    outline: none;\n    text-align: left;\n    font-size: 20px;\n    font-weight: 700;\n    margin-bottom: 20px;\n}\n.button-create-edit{\n    background-color: #0000;\n    border: none;\n    outline: none;\n    color: #4CAF50;\n    position: relative;\n    left: 60%;\n    top: -30%;\n    margin-left: -70px;\n    z-index: 10;\n}\n.requested {\n    margin-left: 6px;\n    opacity: 0.4;\n}\n.label-action {\n    margin-top: -30px;\n}\n.input-suffix {\n    display: flex;\n    flex-direction: row;\n    width: auto;\n    align-items: baseline;\n}\n.suffix{\n    font-weight: 700;\n    font-size: 20px;\n    color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
