import React, { useEffect, useCallback, useRef } from "react";
import arrowLeft from "../../../Assets/ArrowLeft.svg";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "@apollo/client";
import { GET_QRS } from "../../../graphql/getQrs";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import { green } from "@mui/material/colors";
import { useQuery as useQueryParams } from "../../../hooks/useQuery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ListQrs = () => {
  const { productId } = useParams();
  const history = useHistory();
  const query = useQueryParams();
  const role = query.get("role") ? query.get("role") : "";

  // Usamos una única query para obtener los detalles del producto y la lista paginada de QRs
  const { data, loading, error, fetchMore } = useQuery(GET_QRS, {
    variables: { id: productId, limit: 100, offset: 0 },
    fetchPolicy: "network-only" // Opcional: fuerza la consulta desde la red
  });

  // Derivamos la lista de QRs directamente de los datos de Apollo
  const qrs = data && data.product_by_id && data.product_by_id.qrs ? data.product_by_id.qrs : [];

  // Función para cargar más registros usando updateQuery para fusionar resultados
  const loadMore = useCallback(() => {
    if (!data || !data.product_by_id) return;
    fetchMore({
      variables: { offset: qrs.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.product_by_id) return prev;
        const newQrs = fetchMoreResult.product_by_id.qrs;
        // Si no hay nuevos registros, retornamos el estado previo
        if (newQrs.length === 0) return prev;
        return {
          product_by_id: {
            ...prev.product_by_id,
            qrs: [...prev.product_by_id.qrs, ...newQrs]
          }
        };
      }
    });
  }, [data, fetchMore, qrs.length]);

  // Referencia para el elemento de scroll
  const scrollEndRef = useRef(null);

  // Intersection Observer para detectar el scroll al final y cargar más QRs
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    });
    if (scrollEndRef.current) {
      observer.observe(scrollEndRef.current);
    }
    return () => {
      if (scrollEndRef.current) {
        observer.unobserve(scrollEndRef.current);
      }
    };
  }, [loadMore]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [qrCodeUrl, setQrCodeUrl] = React.useState("");
  const [encodedUrlState, setEncodedUrlState] = React.useState("");

  // Utilizamos la data para obtener los detalles del producto
  const product_by_id = data && data.product_by_id ? data.product_by_id : null;

  const handleClick = (qrId) => {
    handleOpen();
    if (product_by_id) {
      const queryParams = `${encodeURIComponent("qr")}=${encodeURIComponent(qrId)}`;
      const qrPage = `${product_by_id.website_url}/?${queryParams}`.replace(/%26/g, "&");
      setEncodedUrlState(qrPage);
      setQrCodeUrl(`https://qrcode.tec-it.com/API/QRCode?data=${product_by_id.website_url}%2F%3F${queryParams}`);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        pt: 4,
        px: 7,
      }}
    >
      <Tooltip title="Regresar" sx={{ mr: 2, mb: 1, pl: 0 }}>
        <IconButton onClick={() => history.goBack()}>
          <Box component="img" src={arrowLeft} alt="edit-icon" width={24} />
        </IconButton>
      </Tooltip>

      {/* Encabezado con detalles del producto */}
      <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center", gap: 2 }}>
        <Typography component="h1" variant="h6" sx={{ fontSize: 24, fontWeight: 400, flexGrow: 1, minWidth: "200px" }}>
          Trazabilidad /
          <Typography component="span" variant="h6" sx={{ fontSize: 24, fontWeight: 700 }}>
            {product_by_id && ` ${product_by_id.name}`}
          </Typography>
        </Typography>

        {role === "QR_ADMIN" && (
          <Button
            variant="contained"
            href={`/qr/${productId}/create?productId=${productId}`}
            sx={{ backgroundColor: green[500], "&:hover": { bgcolor: green[600], color: "#FFF" }, width: "180px", whiteSpace: "nowrap", flexShrink: 0 }}
          >
            Crear nuevo QR
          </Button>
        )}
      </Box>

      <Typography component="h1" variant="h6" sx={{ fontSize: 16, fontWeight: 400, mt: 2 }}>
        Edita las características y los logros sostenibles desde su origen hasta su transporte, procesamiento y fabricación.
      </Typography>

      <Typography component="h1" variant="h6" sx={{ fontSize: 16, fontWeight: 700, color: "#687083", mb: 1, mt: 6 }}>
        Listado de QR
      </Typography>

      {qrs.map((qr, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            width: "100%",
            background: "#fafafa",
            borderRadius: "8px",
            border: "1px solid #ebebeb",
            fontSize: 14,
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px",
            marginBottom: 2,
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            {qr.name && (
              <Typography component="p" sx={{ fontSize: 16, fontWeight: 400, wordBreak: "break-word" }}>
                {qr.name}
              </Typography>
            )}
            <Typography component="p" color="#687083" sx={{ fontSize: 12, fontWeight: 400, wordBreak: "break-word" }}>
              id: {` ${qr.id}`}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 1, flexShrink: 0 }}>
            {role === "QR_ADMIN" && (
              <Tooltip title="Editar QR">
                <IconButton href={`/qr/edit/${qr.id}?productId=${productId}`} rel="noopener noreferrer" color="inherit">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Ver código QR" onClick={() => handleClick(qr.id)}>
              <IconButton color="inherit">
                <QrCodeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver historia del producto">
              <Link href={`${product_by_id ? product_by_id.website_url : '#'}/?qr=${qr.id}`} target="_blank" rel="noopener noreferrer" color="inherit">
                <IconButton color="inherit">
                  <ArrowOutwardIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </Box>
        </Box>
      ))}

      {/* Elemento para detectar el final del scroll */}
      <div ref={scrollEndRef} style={{ height: "20px" }}></div>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <a href={`${encodedUrlState}`} target="_blank" rel="noreferrer">
            <img src={qrCodeUrl} width="150" height="150" alt="QR Code" />
          </a>
        </Box>
      </Modal>
    </Box>
  );
};

export default ListQrs;